import { render, staticRenderFns } from "./Sensor.vue?vue&type=template&id=2eff1bda&scoped=true&"
import script from "./Sensor.vue?vue&type=script&lang=js&"
export * from "./Sensor.vue?vue&type=script&lang=js&"
import style0 from "./Sensor.vue?vue&type=style&index=0&id=2eff1bda&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eff1bda",
  null
  
)

export default component.exports